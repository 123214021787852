<template>
  <div class="mainblock">
    <div class="prodblock">
      <div class="title">
        <h1> XUP Series AC-DC Configurable Power Supplies</h1>
      </div>
      <div class="infobox">
        <div class="infoblock">
          <h3></h3>
          <div class="bullets">
            <ul style=list-style-type:disc>
              <li>Outputs: 1 to 13 fully isolated outputs, 1.8 to 400VDC, up to 11 W/In3</li>
              <li>Output Noise: 0.25% (typ.) 20MHz BW, peak-to-peak</li>
              <li>Universal AC Input: 90-264V(ac)</li>
              <li>AC Line Harmonics: EN61000-2-3</li>
              <li>Operating Temperature: -40 to 75°C</li>
              <li>Safety: UL File E191834, TÜV Cert. CU72082934</li>
              <!--            <li>EtherCAT interface</li>-->
            </ul>
          </div>
          <a href='/images/XUP-Series.pdf' target="_blank">XUP Series Datasheet</a>
        </div>
        <img src="@/assets/02_15_22_0227_ws.jpg" alt="">

      </div>

      <div class="cardtable">

        <div class="upcard">
          <img src="@/assets/02_15_22_0088_ws.jpg" alt="">
          <div class="cinfo">
            <h2>2UP Model:</h2>
            <p>460 Watt, 1-3 outputs</p>
            <p>Size: 2.5"x4.13"x7.3"</p>
            <a href='/images/2UP.pdf' target="_blank">Mounting Drawings</a>
          </div>
        </div>
        <div class="upcard">
          <img src="@/assets/02_15_22_0092_ws.jpg" alt="">
          <div class="cinfo">
            <h2>3UP Model:</h2>
            <p>700 Watt</p>
            <p>1-5 outputs</p>
            <p>Size: 2.5"x5.0"x7.3"</p>
            <a href='/images/3UP.pdf' target="_blank">Mounting Drawings</a>
          </div>
        </div>
        <div class="upcard">
          <img src="@/assets/02_15_22_0096_ws.jpg" alt="">
          <div class="cinfo">
            <h2>4UP Model:</h2>
            <p>1000 Watt</p>
            <p> 2-7 outputs</p>
            <p>Size: 2.5"x5.87"x7.3"</p>
            <a href='/images/4UP.pdf' target="_blank">Mounting Drawings</a>
          </div>
        </div>
        <div class="upcard">
          <img src="@/assets/02_15_22_0100_ws.jpg" alt="">
          <div class="cinfo">
            <h2>5UP Model:</h2>
            <p>1200 Watt</p>
            <p>1-9 outputs</p>
            <p>Size: 2.5"x6.17"x7.3"</p>
            <a href='/images/5UP.pdf' target="_blank">Mounting Drawings</a>
          </div>
        </div>
        <div class="upcard">
          <img src="@/assets/02_15_22_0106_ws.jpg" alt="">
          <div class="cinfo">
            <h2>6UP Model:</h2>
            <p>1200 Watt</p>
            <p>2-11 outputs</p>
            <p>Size: 2.5"x7.63"x7.3"</p>
            <a href='/images/6UP.pdf' target="_blank">Mounting Drawings</a>
          </div>
        </div>
        <div class="upcard">
          <img src="@/assets/02_15_22_0109_ws.jpg" alt="">
          <div class="cinfo">
            <h2>7UP Model:</h2>
            <p>1200 Watt</p>
            <p>2-13 outputs</p>
            <p>Size: 2.5"x8.5"x7.3"</p>
            <a href='/images/7UP_Base.pdf' target="_blank">Mounting Drawings</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "xup"
}
</script>

<style lang="scss" scoped>
.mainblock {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #272547;
  //@media (min-width: 1140px) {
  //  max-width: 1140px;
  //}
}

.prodblock {
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: 1140px;
}


.title {
  margin-top: 40px;
  display: flex;

  h1 {
    font-size: xx-large;
    font-weight: 800;
    color: white;
  }
}

.infobox {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 1140px;

  .infoblock {
    margin-top: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    //background-color: white;
    min-height: 300px;
    color: #b0adad;
    //width: 300px;
    font-size: 16px;
    letter-spacing: -0.02px;
    line-height: 40px;
    a {
      color: lightgrey;
    }
  }

  .infoglow {
    display: flex;
    position: relative;
    background: rgb(39, 37, 71);
    background: radial-gradient(circle at 66%, rgba(39, 37, 171, 1) 0%, rgba(39, 37, 81, 1) 35%, rgba(39, 37, 71, 1) 100%);
    width: 100px;
  }
  img {
    max-height: 250px;
    margin-left: 160px;
  }
}

.cardtable {
  display: grid;
  grid-template-columns: auto auto;
}

.upcard {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  //background-color: #f5f5f5;
  margin-left: 20px;
  margin-top: 20px;
  border-radius: 10px;

  img {
    margin-left: 10px;
    max-height: 200px;
  }

  .cinfo {
    margin-right: 20px;
    max-width: 300px;
  }

  h2 {
    font-size: 20px;
  }

  p {
    color: grey;
    font-size: 16px;
    //letter-spacing: -0.02px;
    line-height: 20px;
  }
}
</style>