<template>
  <div class="mainblock">
    <div class="prodblock">
      <div class="title">
        <h1> Custom OEM Power Systems</h1>
      </div>
      <div class="infobox">
        <div class="infoblock">
          <h3>Power Architects designs and manufactures a large portfolio
            of custom OEM power systems for semiconductor processing equipment,
            test and measurement, and automation.
            Some of these solutions are presented below, offering unique features such as:
          </h3>
          <div class="bullets">
            <ul style=list-style-type:disc>
              <li>Output isolation up to 10kV</li>
              <li>Multiple isolated outputs from 2V to 400V(DC)</li>
              <li>Power level up to 10kW</li>
              <li>Low noise</li>
              <li>Remote enable and programmability</li>
              <li>Telemetry and status check</li>
              <!--            <li>EtherCAT interface</li>-->
            </ul>
          </div>

        </div>
        <!--      <div class="infoglow"> </div>-->
        <img src="@/assets/02_15_22_0445_s.jpg" alt="">
      </div>
      <div class="cardtable">

        <div class="upcard">
          <img src="@/assets/02_15_22_0053_ws.jpg" alt="">
          <div class="cinfo">
            <h2>Electroglas 4090u+ PSMG</h2>
            <p>Power Supply Unit</p>
            <a href='/images/PSMG_Power_System.PDF' target="_blank">Mounting Drawings</a>
          </div>
        </div>
        <div class="upcard">
          <img src="@/assets/02_15_22_0030_ws.jpg" alt="">
          <div class="cinfo">
            <h2>Customer #1</h2>
            <p>PSU, DAS, 3.3/5.7/6.5/16.5</p>
            <p>325VDC Power Supply</p>
          </div>
        </div>
        <div class="upcard">
          <img src="@/assets/02_15_22_0046_ws2.jpg" alt="">
          <div class="cinfo">
            <h2>Customer #2</h2>
            <p>2400W 60VDC 5kV </p>
            <p>isolated power supply</p>
          </div>
        </div>
        <div class="upcard">
          <img src="@/assets/02_15_22_0041_ws.jpg" alt="">
          <div class="cinfo">
            <h2>Customer #3</h2>
            <p>Deflection Power Supply</p>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "oem"
}
</script>


<style lang="scss" scoped>
.mainblock {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #272547;
}

.prodblock {
  max-width: 95%;
}
.title {
  margin-top: 40px;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: xx-large;
    font-weight: 800;
    color: white;
  }
}

.infobox {
  //position: relative;
  display: flex;
  flex-direction: row;

  //width: 95%;
  justify-content: center;
  align-items: center;
  max-width: 1140px;

  .infoblock {
    margin-top: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 20px;
    //background-color: white;
    min-height: 300px;
    color: #b0adad;
    //width: 300px;
    letter-spacing: -0.02px;
    line-height: 40px;
  }
  img {
    display: none;
    max-height: 250px;
    margin-left: 160px;
  }
  .bullets {
    margin-left: 40px;
    font-size: 20px;
  }
}

.cardtable {
  display: flex;
  flex-direction: column;
  align-items: center;
  //grid-template-columns: auto auto;
}

.upcard {
  display: flex;
  flex-direction: column;
  align-items: center;
  //background-color: #f5f5f5;
  background-color: white;
  //margin-left: 20px;
  margin-top: 20px;
  border-radius: 10px;

  max-width: 90%;
  img {
    margin-left: 10px;
    max-width: 80%;
    height: auto;
  }

  .cinfo {
    margin-right: 20px;
    max-width: 300px;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 20px;
  }

  p {
    color: grey;
    font-size: 16px;
    //letter-spacing: -0.02px;
    line-height: 20px;
  }
}
</style>