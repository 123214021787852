<template>

</template>

<script>
export default {
  name: 'Init',
  methods: {

    isMobile() {
      console.log("Use mobile site" + screen.width)
      if( screen.width <= 760 ) {
        return true;
      }
      else {
        return false;
      }
    }
  },
  created() {
    if (this.isMobile()) {
      console.log("Use mobile site")
      this.$router.push('/homem');
    }
    else {
      this.$router.push('/Home');
      console.log("Use desktop site")
    }
  }
}
</script>

<style scoped>

</style>