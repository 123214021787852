
<template>

 <div class="mainblock">
   <div class="vids">
     <VideoBackground class="videoplayer"
                      :src="require('@/assets/1035695039-hd.mp4')"


     >
<!--       rectangle-42-1@1x.png-->
       <div class="slogans">
         <div class="slog1"><h1>Custom Power Solutions for: </h1></div>
         <div class="slog2">Semiconductor Fabrication </div>
         <div class="slog3">Automation</div>
         <div class="slog4">Instrumentation</div>
       </div>
     </VideoBackground>

   </div>
   <div class="patext">
     <div class="patextblock" >
       <h1>{{customAndValueAdd}}</h1>
       <p>{{foundedIn1997Pow}}</p>
     </div>
     <div class="image013"><img src="@/assets/02_15_22_0425_s.jpg" alt="">
     </div>

   </div>
   <div class="pacards">
     <div class="stitle">
         <h1> Choose our Products</h1>
     </div>
     <div class="cardgroup">
       <div class="card" @click="onClick('xup')">
         <img src="@/assets/02_15_22_0111_ws.jpg" alt="" >
         <div class="card-text">
           <h2>XUP Series Power Supplies</h2>
         </div>
       </div>
<!--       -->
       <div class="card" @click="onClick('valadd')">
         <img src="@/assets/02_15_22_0084_ws.jpg" alt="" >
         <div class="card-text">
           <h2>Value-added Cases</h2>
         </div>
       </div>
<!--       -->
       <div class="card" @click="onClick('custom')">
         <img src="@/assets/02_15_22_0049_ws.jpg" alt="" >
         <div class="card-text">
           <h2>Custom OEM Power</h2>
         </div>
       </div>
     </div>
   </div>
<!--   -->
   <div class="neonsign">
     <h3>Power Architects Corporation is a member of EtherCAT Technology Group</h3>
     <div class="ethercat">
     <img alt=""
          src="@/assets/ethercat.gif"
          @click="onClickExt('https://www.ethercat.org/en/members/members_1E7ECFA8D5F849498B75CBC77FAE4B85.htm')"
          href="">
       <a href="https://www.ethercat.org/en/members/members_1E7ECFA8D5F849498B75CBC77FAE4B85.htm" target="_blank" >visit</a>
     </div>
   </div>
<!--   <img-->
<!--       class="ellipse-1"-->
<!--       src="https://anima-uploads.s3.amazonaws.com/projects/620f2f861de6e5479345c303/releases/620f2f8f88677fa74321be61/img/ellipse-1@1x.svg"-->
<!--   />-->
 </div>
</template>

<script>

import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

import VideoBackground from 'vue-responsive-video-background-player'
import router from "@/router";

export default {
  name: "Home",
  components: { Carousel,
    Slide,
    Pagination,
    Navigation,
    VideoBackground},
  data() {
    return {
      settings:{
        "arrows": false,
        "dots": true,
        "infinite": true,
        "slidesToShow": 3,
        "slidesToScroll": 1,
        "autoplay": true,
        "speed": 2000,
        "autoplaySpeed": 2000,
        "cssEase": "linear"
      },
      carousel_items: [
        {message: "hmmm", img: "@/assets/rectangle-42-1@1x.png"},
        {message: "hmmm2", img: "@/assets/rectangle-42-2@1x.png"}
      ],
      customAndValueAdd: "Custom and Value-Added Off-the-Shelf Power Systems",
      foundedIn1997Pow: "Founded in 1997, Power Architects Corporation designs and manufactures custom industrial AC/ DC power systems and semi-custom modular AC/DC power supplies for semiconductor wafer processing equipment and test instrumentation. For over 20 years, the Company builds highly reliable medium-to-high power, high efficiency and power density systems featuring high scalability and multiple output flexibility up to 400V(dc), high-voltage isolation up to 10kV, and low noise. Located in Silicon Valley, Power Architects Corporation is a recognized supplier of power solutions for most semiconductor industry leaders."

    }
  },
  methods: {
    onClick(link) {
      console.log("routing on Click: " + link)
      router.push(link)
    },
    onClickExt(link) {
      console.log("routing external on Click: " + link)
      window.open(link)
    }
  }
}




</script>

<style lang="scss" scoped>

.mainblock {
  display: flex;
  background-color: #272547;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.vids {
  display: flex;

  img {
    max-height: 700px;
  }
  flex-direction: column;
  align-content: center;
  justify-content: center;

  .videoplayer {
    max-height: 700px;
    height: 100vh;
    width: 1140px;
    display: flex;
    flex-direction: column;
    //align-content: center;
    //justify-content: center;
    //justify-items: center;


    .slogans {

      display: flex;
      flex-direction: column;
      //position: relative;
      position: absolute;
      top: 300px;
      //top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1000px;
      max-height: 650px ;

      .slog1 {
        //display: flex;
        align-content: center;
        justify-content: center;
        h1 {
          font-size: 60px;
          color: #dc0808;
        }
      }
      .slog2 {
        margin-top: 25px;
        margin-left: 70px;
        //display: flex;
        align-content: center;
        justify-content: center;
        color: #272547;
        font-size: xx-large;
        font-weight: 800;
        animation: 2s anim-lineUp ease-out

      }
      @keyframes anim-lineUp {
        0% {
          opacity: 0;
          transform: translateY(80%) translateX(110%);
        }
        20% {
          opacity: 0;
        }
        50% {
          opacity: 1;
          transform: translateY(0%) translateX(0%);

        }
        100% {
          opacity: 1;
          transform: translateY(0%);
        }
      }
      .slog3 {
        margin-top: 115px;
        margin-left: 400px;
        //display: flex;
        align-content: center;
        justify-content: center;
        color: #272547;
        font-size: xx-large;
        font-weight: 800;
        animation: 4s anim-lineUp ease-out
      }
      .slog4 {
        margin-top: 150px;
        margin-left: 600px;
        //display: flex;
        align-content: center;
        justify-content: center;
        color: #272547;
        font-size: xx-large;
        font-weight: 800;
        animation: 6s anim-lineUp ease-out
      }
    }
  }
}

.patext {
  display: flex;
  flex-direction: row;
  margin-top: 40px;

  //margin-left: 40px;
  @media (min-width: 1140px) {
    max-width: 1140px;
  }


  .image013 {
    display: flex;
    position: relative;

    img {
      margin-top: 30px;
      max-height: 300px;
      margin-left: 30px;
    }
  }

  .patextblock {
    //position: relative;
    display: flex;
    flex-direction: column;
    transition: .5s ease all;
    margin-top: 20px;
    h1 {
      font-size: xx-large;
      font-weight: 800;
      color: white;
    }
    p {
      color: gray;
      font-size: 20px;
      letter-spacing: -0.02px;
      line-height: 34px;
      margin-top: 30px;
      min-height: 306px;
    }
  }
}

section {
  width: 40%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  //width: 90%;
  margin: 0 auto;
  @media (min-width: 1140px) {
    max-width: 1140px;
  }
  h1 {
    font-size: xx-large;
    font-weight: 800;
    color: white;
    padding: 16px;
    //margin-left: 16px;
    transition: .5s ease all;
  }
  p{
    color: gray;
    padding: 16px;
    font-size: 16px;
    //margin-left: 16px;
    transition: .5s ease all;
    letter-spacing: -0.02px;
    line-height: 34px;
    margin-top: 30px;
    min-height: 306px;
    width: 590px;
  }
}

.pacards {
  position: relative;
  display: flex;
  flex-direction: column;
width: 1140px;
  margin-top: 30px;
  //@media (min-width: 1140px) {
  //  max-width: 1140px;
  //}
  @media (min-width: 1140px) {
    max-width: 1140px;
  }

  .stitle {
    font-size: xx-large;
    font-weight: 800;
    color: white;
  }

  .cardgroup {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 20px;
    //margin: auto;
    //flex-wrap: wrap;
    //align-content: space-between;
    flex-direction: row;
    @media (min-width: 1140px) {
      max-width: 1140px;
    }

    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: .5s ease all;
      margin-left: 45px;
      margin-top: 40px;

      img {
        transition: .5s ease all;
        max-height: 210px;
        max-width: 320px;
        border-radius: 20px;
        box-shadow: 10px 10px 8px #4b4949;
      }

      h2 {
        margin-top: 20px;
        color: lightgray;
      }

      .learnmore {
        margin-top: 15px;
        color: #cdf2fe;
        transition: .5s ease all;
        a{
          color: lightgrey;
          transition: .5s ease all;
        }
      }

      &:hover {
        //background-color: #cdf2fe;
        cursor: pointer;
        img {
          box-shadow: 10px 10px 8px #b0adad;
        }

        h2 {
          color: white;
        }

        .learnmore {
          a {
            color: white;
          }
          color: blue;
        }
      }
    }
  }
}

.neonsign {
  //background: #272547;
  //background: linear-gradient(45deg, #322e60, #322e7c);
  //box-shadow: 10px 10px 8px #4b4949;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  color: gray;
  //align-content: space-between;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 90%;
  height: 90px;
  border-radius: 20px;
  h3 {
    margin-left: 30px;
  }
  img{
    margin-left: 30px;
    height: 40px;
  }
  .ethercat {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    a {
      transition: .5s ease all;
      margin-left: 16px;
    }
    &:hover {
      //background-color: #cdf2fe;

      img {
        box-shadow: 5px 5px 8px #b0adad;

      }
      a {

        margin-top: 6px;
        color: lightgrey;
      }
    }
  }
  @media (min-width: 1140px) {
    max-width: 1140px;
  }
}

@keyframes neonsign {
  from {transform: translateX(0);     margin-left: 0;}
  to   {transform: translateX(-100%); margin-left: 100%; }
}
.carrds {
  display: flex;
  flex-direction: row;

  //img {
  //  box-shadow: 5px 10px #333333;
  //}
}

.card1 {
  max-height: 380px;
}

@media (max-width: 800px) {
  .carrds{
    flex-direction: column;
  }
}
</style>