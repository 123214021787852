<template>
<header :class="{'scrolled-nav': scrolledNav}">
  <nav>
    <div class="branding" @click="onClick('/')" >
      <img src="@/assets/logo1-2x@2x.png" alt="PowerArchitects" />
      <div class="coname">
        <h1>Power Architects</h1>
        <h2>Custom Power and Control</h2>
      </div>
    </div>
    <div v-show="!mobile" class="navigation">
         <router-link class="menu-item" :to="{ name: 'Home' }">Home </router-link>
        <dropdown_prod title="Products" :items="services" />
        <router-link class="menu-item" :to="{ name: 'custom' }">Custom OEM Power </router-link>
        <router-link class="menu-item" :to="{ name: 'About' }">About </router-link>
        <router-link class="menu-item" :to="{ name: 'Contacts' }">Contacts </router-link>
<!--        <div class="menu-item"><a href="Contacts">Contact</a></div>-->
    </div>
    <div class="icon">
      <em @click="toggleMobileNav" v-show="mobile" class="far fa-bars" :class="{ 'icon-active': mobileNav }"></em>
    </div>
    <transition name="mobile-nav">
      <ul v-show="mobileNav" class="dropdown-nav" @click="toggleMobileNav">
        <li><router-link class="link" :to="{ name: 'Homemob' }">Home </router-link></li>
        <li><router-link class="link" :to="{ name: 'XUPmob' }">XUP Series </router-link></li>
        <li><router-link class="link" :to="{ name: 'valaddmob' }">Value Added Cases </router-link></li>
        <li><router-link class="link" :to="{ name: 'custommob' }">Custom Series </router-link></li>
        <li><router-link class="link" :to="{ name: 'Aboutmob' }">About </router-link></li>
        <li><router-link class="link" :to="{ name: 'Contactsmob' }">Contacts </router-link></li>
      </ul>
    </transition>
  </nav>
</header>
</template>
<!--https://www.youtube.com/watch?v=u2AwJAFeaKc-->


<script>
import dropdown_prod from "@/components/dropdown_prod"
import router from "@/router";
export default {
  name: "NavigationBar",
  components: {
    dropdown_prod
  },
  data(){
    return {
      scrolledNav: null,
      mobile: true,
      mobileNav: true,
      windowWidth: null,
      services: [
        {
          title: 'XUP Series',
          link: 'xup'
        },
        {
          title: 'Value Added Cases',
          link:'valadd'
        }
      ]
    }
  },
  created() {
    window.addEventListener('resize', this.checkScreen);
    this.checkScreen();
    this.mobileNav = false;
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },
  methods: {
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },
    updateScroll(){
      const scrollPosition = window.scrollY;
      if (scrollPosition > 50 ){
        this.scrolledNav = true;
        return;
      }
      this.scrolledNav = false;


    },
    checkScreen(){
      this.windowWidth = window.innerWidth;
      if(this.windowWidth <= 750){
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;

    },
      onClick(link) {
        console.log("routing on Click: " + link)
        router.push(link)
    }
  }
}
</script>

<style lang="scss" scoped>

header {
  background-color: #272547;
  z-index: 99;
  width: 100%;
  position: relative;
  transition: .5s ease all;
  color: white;

  nav .menu-item {
    color: #FFF;
    padding: 10px 20px;
    position: relative;
    text-align: center;
    border-bottom: 3px solid transparent;
    display: flex;
    transition: 0.4s;
    margin-top: 10px;
    text-decoration: none;
  }
  nav .menu-item.active,
  nav .menu-item:hover {
    background-color: #444;
    border-bottom-color: #FF5858;
  }
  nav .menu-item a {
    color: inherit;
    text-decoration: none;
  }


  nav .router-link-active,
  nav .router-link-exact-active {
    border-bottom-color: #FF5858;
  }



  nav {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 12px 0;
    //transition: .5s ease all;
    width: 90%;
    margin: 0 auto;
    @media (min-width: 1140px) {
      max-width: 1140px;
    }

    ul,
    .link {
      font-weight: 500;
      color: white;
      list-style: none;
      text-decoration: none;
    }

    li {
      text-transform: uppercase;
      padding: 16px;
      margin-left: 16px;
    }

    .link {
      font-size: 14px;
      transition: .5s ease all;
      padding-bottom: 4px;
      border-bottom: 1px solid transparent;

      &:hover {
        color: blue;
        border-color: blue;
      }
    }

    .branding {
      display: flex;
      align-items: center;
      transition: .5s ease all;
      img {
        width: 70px;
      //clamp(2rem, -0.875rem + 8.333vw, 3.5rem);
      }
      .coname {
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        h1 {
          font-weight: bold;
          font-size: clamp(1rem, -0.875rem + 8.333vw, 2.5rem);
        }
        h2 {
          margin-top: 1rem;
          font-weight: bold;
          font-size: 0.6rem;
        //clamp(0.5rem, -0.875rem + 8.333vw, 1.5rem);
          max-width: 90%;
          @media (min-width: 600px) {
            font-size: 1rem;
          }
        }
      }
      &:hover {
        h1 {color: red;}
        h1 {border-color: blue;}
        h2 {color: red;}
        h2 {border-color: blue;}
        cursor: pointer;
      }
    }
    .navigation {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-end;
    }
    .icon {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 24px;
      height: 100%;
      i {
        cursor: pointer;
        font-size: 24px;
        transition: .8s ease all;
      }
    }

    .icon-active {
      transform: rotate(180deg);
    }

    .dropdown-nav {
      display: flex;
      flex-direction: column;
      position: fixed;
      width: 100%;
      max-width:250px;
      height: 100%;
      background-color: white;
      top: 0;
      left: 0;
      li {
        margin-left: 0;
        .link{
          color: black;
        }
      }

    }

    .mobile-nav-enter-active,
    .mobile-nav-leave-active {
      transition: 1s ease all;
    }

    .mobile-nav-enter-from,
    .mobile-nav-leave-to {
      transform: translateX(-250px);
    }

    .mobile-nav-enter-to {
      transform: translateX(0);
    }
  }
}

.scrolledNav {
  background-color: black;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

  nav {
    padding: 8px 0;
    .branding {
      img {
        width: 20px;
        box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      }
    }
  }
}

</style>