<template>
<div class="menu-item" @mouseenter="isOpen = true"  @mouseleave="closeDropdown" >
  <a href="#">
    {{title}}
  </a>
  <svg viewBox="0 0 1030 638" width="10">
    <path d="M1017 68L541 626q-11 12-36 12t-26-12L13 68Q-3 49 6 24.5T39 0h952q24 0 33 24.5t-7 43.5z" fill="#FFF"></path>
  </svg>
  <transition name="fade" appear>
    <div class="sub-menu" v-if="isOpen" @mouseleave="closeDropdown" >
      <div v-for="(item, i) in items" :key="i" class="menu-item">
<!--        <a :href="item.link">{{ item.title }}</a>-->
        <router-link :to="{ name: item.link }">{{ item.title }} </router-link>
      </div>
    </div>
  </transition>
</div>
</template>

<script>
export default {
  name: "dropdown_prod",
  props: ['title', 'items'],
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    closeDropdown() {
      this.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
nav .menu-item svg {
  width: 10px;
  margin-left: 10px;
}
nav .menu-item .sub-menu {
  color: white;
  position: absolute;
  background-color: #272547;
  top: calc(100% + 18px);
  left: 50%;
  transform: translateX(-50%);

  width: 150px;
  border-radius: 0px 0px 16px 16px;
}

nav .menu-item.active,
nav .menu-item:hover {
  background-color: #444;
  border-bottom-color: #FF5858;
}

nav .menu-item a {
  color: inherit;
  text-decoration: none;
}

nav .sub-menu {
  .menu-item {
    margin-top: 5px;
    margin-bottom: 10px;
    color: #FFF;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: all .5s ease-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>