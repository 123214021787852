import  from './App.vue'
<template>
  <div class="app">
    <webapp-header-dd />
    <router-view />
    <webapp-footer />
  </div>
</template>

<script>
import NavigationBar from '@/components/navigationBar.vue'
import NavigationBarDD from '@/components/NavigationBarDD'
import Footer from '@/components/footer.vue'
import VideoBackground from 'vue-responsive-video-background-player'

export default {
  name: "App",
  components: {
    'webapp-header-dd': NavigationBarDD,
    'webapp-header': NavigationBar,
    'webapp-footer': Footer,
    'video-background': VideoBackground
  }
};

</script>

<style lang="scss" scoped>

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
}
html, body {
  height: 100%;
}

.app {
  min-height: 100vh;
  position: relative;
  background-color: #d5d3d1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.container {
  padding: 0 20px;
  max-width: 1140px;
  margin: 0 auto;
}
</style>

