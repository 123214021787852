<template>
  <footer>
    <div class="fot_body">
      <div class="branding">
        <img src="@/assets/logo1-2x@2x.png" alt="PowerAchitects"/>
        <div class="cname"  @click="onClick('/')">
           <h1> Power <br>Architects </h1>
        </div>
      </div>
      <ul class="group-nav" v-show="!mobileNav">
        <li><router-link class="link" :to="{ name: 'Home' }">Home</router-link></li>
        <li><router-link class="link" :to="{ name: 'About' }">About us</router-link></li>
        <li><router-link class="link" :to="{ name: 'Contacts' }">Contacts</router-link></li>
      </ul>
      <ul class="group-nav" v-show="mobileNav">
        <li><router-link class="link" :to="{ name: 'Homemob' }">Home</router-link></li>
        <li><router-link class="link" :to="{ name: 'Aboutmob' }">About us</router-link></li>
        <li><router-link class="link" :to="{ name: 'Contactsmob' }">Contacts</router-link></li>
      </ul>
      <div class="pat_icons">
        <div class="madeinca">

          <img
              class="image-1"
              src="@/assets/image-8@2x.png"
              alt=""
              @click="onClickExt('https://www.cmtc.com/made-in-california-manufacturers/power-architects?hsLang=en')"
          />
          <a href="https://www.cmtc.com/made-in-california-manufacturers/power-architects?hsLang=en" target="_blank" >visit</a>
        </div>
        <img
            class="image-2"
            src="@/assets/MakeUSALogo.jpg.jpg"
            alt=""
        />
      </div>
    </div>
    <div class="copyright">
      <h1>© 2022 Power Architects </h1>
    </div>


  </footer>
</template>

<script>
import router from "@/router";

export default {
  name: "Footer",
  data() {
    return {
      mobileNav: true,
    }
  },
  created() {
    window.addEventListener('resize', this.checkScreen);
    this.checkScreen();
    this.mobileNav = false;
  },
  methods: {
    onClick(link) {
      console.log("routing on Click: " + link)
      router.push(link)
    },
    onClickExt(link) {
    console.log("routing external on Click: " + link)
    window.open(link)
  },
    checkScreen(){
      this.windowWidth = window.innerWidth;
      if(this.windowWidth <= 750){
        this.mobileNav = true;
        return;
      }
      this.mobileNav = false;

    },
  }
}
</script>

<style lang="scss"  scoped>

footer {
  background-color: #272547;
  z-index: 99;
  width: 100%;
  position: relative;
  transition: .5s ease all;
  color: white;

  .fot_body {
    //position: relative;
    display: flex;
    align-items: center;
    padding: 12px 0;
    width: 100%;
    margin: 0 auto;
    @media only screen and (max-width: 600px) {
      max-width: 320px;
      flex-direction: column;
    }
    @media only screen and (min-width: 600px) {
      max-width: 1140px;
      flex-direction: row;
    }


    .branding {
      display: flex;
      align-items: center;

      img {
        width: 75px;
        // width: clamp(2rem, -0.875rem + 8.333vw, 3.5rem);
        transition: .5s ease all;
      }

      h1 {
        padding: 16px;
        margin-left: 16px;
        transition: .5s ease all;
        font-weight: 800;
        letter-spacing: 2px;

        &:hover {
          color: red;
          border-color: blue;
          cursor: pointer;
        }
      }
    }

    .group-nav {
      position: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      justify-content: flex-end;
      width: 90%;

      ul,
      .link {
        font-weight: 500;
        color: white;
        list-style: none;
        text-decoration: none;
      }

      li {
        text-transform: uppercase;
        padding: 16px;
        //margin-left: 16px;
      }

      .link {
        font-size: 14px;
        transition: .5s ease all;
        padding-bottom: 4px;
        border-bottom: 1px solid transparent;

        &:hover {
          color: blue;
          border-color: blue;
          cursor: pointer;
        }
      }

    }

    .pat_icons {
      position: relative;
      display: flex;
      flex-direction: row;

      flex: 1;
      //justify-content: flex-end;
      //max-width: 125px;

      img {
        transition: .5s ease all;
      }

      .madeinca {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        align-items: center;
        a {
          transition: .5s ease all;
          margin-left: 16px;
        }
        &:hover {
          //background-color: #cdf2fe;
          cursor: pointer;
          img {
            box-shadow: 5px 5px 8px #b0adad;

          }
          a {

            margin-top: 6px;
            color: lightgrey;
          }
        }
      }

      @media only screen and (max-width: 600px)      {
        img {
          max-height: 45px;
          padding: 5px;
        }
        max-width: 270px;
        //justify-content: top;
        //align-items: center
      }
      @media only screen and (min-width: 600px) {
        //max-width: 125px;
        justify-content: right;
        //align-items: flex-end;
        max-width: 270px;
        img {
          width: 125px;
          height: 90px;
          margin-left: 16px;
        }
      }

    }

    .copyright {
      display: flex;
      align-items: center;
      padding: 12px 0;
      width: 100%;
      margin: 0 auto;
      //@media (min-width: 1140px) {
      //  max-width: 1140px;
      //}
      //@media (min-width: 480px) {
      //  max-width: 480px;
      //}
      position: center;
      //position: relative;
      //padding: 16px;
      //margin-right: 16px;
    }
  }

}

</style>