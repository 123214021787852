<template>
  <div class="mainblock">
    <div class="topimage">
      <h1>Products</h1>
    </div>



    <div class="bkicons">

      <div class="card_i" @click="changeProd('Xup')">
        <h2>XUP Series</h2>
      </div>
      <div class="card_i" @click="changeProd('vadd')">
         <h2>Value-added</h2>
      </div>
      <div class="card_i" @click="changeProd('oem')">
        <h2>Custom OEM</h2>
      </div>
    </div>
<!--    -->

  <div >
    <component :is="dynamicComponent"></component>
  </div>
<!--    -->

  </div>
</template>

<script>
import oem from "@/components/oem.vue";
import Xup from "@/components/xup";
import vadd from "@/components/valadd";
export default {
  name: "Products",
  components: {Xup, oem, vadd},

  data() {
    return {
      dynamicComponent: "Xup"
    }
  },
  methods: {
    changeProd(comp){
      this.dynamicComponent = comp;
      console.log(comp);
    }
  },

}
</script>

<style lang="scss" scoped>
.mainblock {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #272547;
  img {
    height: 540px;
    justify-content: flex-end;
    left: 0;
    max-width: 1140px;
  }

}

.topimage{
  background-image: url('~@/assets/1182919789.jpg');
  height: 540px;
  width: 1140px;
  max-width: 1140px;
  display: flex;
  flex-direction: inherit;
  align-items: center;
  justify-content: center;
  h1 {
    background-image: -webkit-linear-gradient(left, #cdf2fe, blue);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-decoration-style: solid;
    font-size: 120px;
    font-weight: 700;
  }
}

.bkicons {
  display: flex;
  flex-direction: row;
  align-items: center;
  //color: white;
  justify-content: space-evenly;
  //max-width: 1140px
  //@media (min-width: 1140px) {
  //  max-width: 1140px;
  //}
  h2 {
    font-size: large;
    font-weight: 800;
    color: #272547;
  }
  .card_i {
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #cdf2fe;
    min-height: 60px;
    min-width: 350px;
    margin-left: 20px;
    border-radius: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    &:hover {
      //background-color: #cdf2fe;
        box-shadow: 10px 10px 8px #b0adad;
      h2 {
        color: white;
      }

    }
  }
}

</style>