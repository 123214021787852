<template>
  <div class="mainblock">
    <div class="prodblock">
      <div class="title">
        <h1> Customizable Value-Added Products and Solutions</h1>
      </div>
      <div class="infobox">
        <div class="infoblock">
          <h3>Power Architects offers a number of value-added products that
            are highly-customizable and carry the full set of safety approvals.
            These products reduce the design cycle time while still offering the
            proven field reliability and high performance of the XUP Series
            configurable power supplies. Custom options include:</h3>
          <div class="bullets">
            <ul style=list-style-type:disc>
              <li>Custom enclosures</li>
              <li>Customer-specified power and control interface connectors</li>
              <li>Telemetry and status check</li>
              <li>Remote enable and programmability</li>
  <!--            <li>EtherCAT interface</li>-->
            </ul>
          </div>
        </div>
        <img src="@/assets/02_15_22_0441_s.jpg" alt="">
      </div>
      <div class="cardtable">

        <div class="upcard">
          <img src="@/assets/02_15_22_0084_ws.jpg" alt="">
          <div class="cinfo">
            <h2>19" Rack Pluggable AC-DC Power Supplies:</h2>
            <ul>
              <li>700W and 1400W Pluggable XUP Series</li>
              <li>1 to 14 outputs 1.8-350VDC</li>
              <li>Safety: TUV NRTL Approved</li>
              <li>Single Chassis: 5"x3"x10"(14"), 700W</li>
              <li>Dual Chassis: 5"x6"x10"(14"), 1400W</li>
            </ul>
          </div>
        </div>
        <div class="upcard">
          <img src="@/assets/02_15_22_0062_ws.jpg" alt="">
          <div class="cinfo">
            <h2>Adjustable AC/DC Power Supplies:</h2>
            <ul>
              <li>5V to 50V</li>
              <li>5V to 65V</li>
              <li>5V to 80V</li>
              <li>5V to 120V</li>
              <li>Universal Input 90-264VAC, 700W or 1400W</li>
              <li>Size: 2.5"x 5.87"x 9"</li>
              <li>Safety: UL File E191834, TÜV CU 72092045</li>
            </ul>
            <a href='/images/4UPFB-data.pdf' target="_blank">Datasheet</a>
            <a href='/images/4UPFB-dwg.pdf' target="_blank">Mounting Drawings</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "vadd"
}
</script>


<style lang="scss" scoped>

.mainblock {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #272547;
}

.prodblock {
  max-width: 95%;
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.title {
  margin-top: 40px;
  h1 {
    font-size: xx-large;
    font-weight: 800;
    color: white;
  }
}

.infobox {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 1140px;

  .infoblock {
    margin-top: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 300px;
    color: #b0adad;
    font-size: 16px;
    letter-spacing: -0.02px;
    line-height: 40px;

    li {
      display: list-item;
    }
    .bullets{
      margin-left: 40px;
      font-size: 20px;
    }
    h3 {
      font-size: 20px;
    }
  }

  .infoglow {
    display: flex;
    position: relative;
    background: rgb(39, 37, 71);
    background: radial-gradient(circle at 66%, rgba(39, 37, 171, 1) 0%, rgba(39, 37, 81, 1) 35%, rgba(39, 37, 71, 1) 100%);
    width: 40%;
  }
  img {
    display: none;
    max-height: 250px;
    margin-left: 160px;
  }
}

.cardtable {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  align-items: center;
  margin-bottom: 40px;
}

.upcard {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  //margin-left: 20px;
  margin-top: 20px;
  border-radius: 10px;
  max-width: 90%;
  //min-width: 750px;
  img {
    margin-left: 10px;

    max-width: 80%;
    height: auto;
  }
  .cinfo {
    display: flex;
    flex-direction: column;
    //margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 80%;
  }
  h2 {
    font-size: 20px;
  }
  p {
    margin-top: 10px;
    color: grey;
    font-size: 16px;
    line-height: 20px;
  }
  li {
    margin-top: 5px;
    color: grey;
  }
  ul {
    margin-top: 5px;
    margin-bottom: 10px;
  }
}
</style>