<template>

  <div class="mainblock">

    <div class="patext">
      <div class="patextblock">
        <h1>Our History</h1>
        <p>{{ foundedIn1997Pow }}</p>
      </div>

      <Carousel :settings='settings' :breakpoints='breakpoints' >
        <slide v-for="i in images" :key="i" >
          <img :src="i" alt="" style="max-width: 100%">

        </slide>

        <template #addons>
          <Navigation />
          <Pagination />
        </template>
      </Carousel>



      <div class="img_stack">
        <div class="imageandspot">
          <img class="img1" src="@/assets/02_15_22_0132_s.jpg" alt="">
          <img class="img2" src="@/assets/02_15_22_0308_s.jpg" alt="">
          <img class="img3" src="@/assets/02_15_22_0425_s.jpg" alt="">
        </div>
      </div>
    </div>

    <div class="partners">
      <h1>Our Partners</h1>
      <p>In addition to the OEM power solutions, our group of business units provides a wide range of
        services, including electrical and mechanical designs, fabrication, and functional testing.</p>
      <div class="neonsign">

        <div class="cardgroup">

          <div class="card1">
            <div class="gr1">
            <img src="@/assets/adem.png" alt="">
              <h2>ADEM LLC</h2>
          </div>
            <div class="learnmore">
              <a href="http://www.ademllc.com" target="_blank" >visit</a>
            </div>
          </div>
          <!--       -->
          <div class="card1">
            <img src="@/assets/abc.png" alt="">

            <div class="learnmore">
              <a href="https://www.abcassembly.com" target="_blank" >visit</a>
            </div>
          </div>
          <!--       -->
          <div class="card1">

            <div class="gr3">
              <img src="@/assets/logo1-2x@2x.png" alt="">
              <h2>Power Architects</h2>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
export default {
  name: "Aboutmob",
  components: {

    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      foundedIn1997Pow: "Founded in 1997 in San Jose, CA, Power Architects is a " +
          "recognized supplier of configurable power supplies, " +
          "semi-custom power solutions, and custom OEM power " +
          "systems for semiconductor processing equipment, " +
          "instrumentation, and automation. Our highly capable " +
          "engineering team of engineers delivers great customer " +
          "support to turn every unique power requirement into a " +
          "high-performance power system. Our proven field " +
          "reliability and on-time delivery for over 20 years in the " +
          "industry has won Power Architects recognition with " +
          "most semiconductor industry leaders in Silicon Valley " +
          "and around the world.",
      images: [
          "images/02_15_22_0132_s.jpg",
          "images/02_15_22_0308_s.jpg",
          "images/02_15_22_0425_s.jpg",
        "images/02_15_22_0445_s.jpg"
      ],
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 3.5,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 5,
          snapAlign: 'start',
        },
      },
    }
  }
}
</script>

<style lang="scss" scoped>

.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color:  var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
  width: 100%;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}


.mainblock {

  display: flex;
  background-color: #272547;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-size: xx-large;
  font-weight: 800;
  color: white;
}

.imgslider {
  min-height: 300px;
  //height: 600px;
  width: 95%;
  margin-top: 40px;
  margin-bottom: 40px;
  .next {
    display: none;
  }
}

.patext {
  display: flex;
  flex-direction: column;
  //margin-left: 40px;
  max-width: 95%;


  .patextblock {
    display: flex;
    flex-direction: column;
    position: relative;
    //width: 50%;
    p {
      color: grey;
      font-size: 16px;
      letter-spacing: -0.02px;
      line-height: 34px;
      margin-top: 30px;
      min-height: 306px;
      //width: 480px;
    }
  }

  .img_stack {
    display: none;
    position: relative;
    margin-left: 40px;
  }

  .imageandspot {
    display: grid;
    grid-template-columns: repeat( 6, 10fr);
    position: relative;
    //background: rgb(39, 37, 71);
    //background: radial-gradient(circle at 66%, rgba(39, 37, 171, 1) 0%, rgba(39, 37, 81, 1) 35%, rgba(39, 37, 71, 1) 100%);
    width: 40%;

    img {

      max-width: 320px;
      max-height: 320px;
    }

    .img1 {
      grid-column: 4 / span 6;
      grid-row: 1 /span 3;
      padding-top: 20%;
      //z-index: 96;
    }

    .img2 {
      grid-column: 1 / span 4;
      grid-row: 3 /span 4;
      padding-top: 20%;
      //padding-top: 20%;
      //z-index: 97;
    }

    .img3 {
      grid-column: 6 / span 8;
      grid-row: 5 /span 7;
      padding-top: 50%;
      //z-index: 98;
    }
  }

}
.partners{
  display: flex;
  margin-top: 45px;
  flex-direction: column;
  //width: 100%;
  max-width: 95%;

  p {
    color: grey;
    font-size: 16px;
    letter-spacing: -0.02px;
    line-height: 34px;
    margin-top: 30px;
    //min-height: 306px;
    //width: 480px;
  }
}
  .neonsign {
    background: #272547;
    background: linear-gradient(45deg, #322e60, #322e7c);
    //box-shadow: 10px 10px 8px #4b4949;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    //justify-content: center;
    //justify-content: space-between;
    justify-content: space-evenly;
    //align-content: space-between;
    margin-top: 40px;
    margin-bottom: 40px;
    //width: 90%;
    max-width: 95%;
    height: auto;
    border-radius: 20px;

    img {
      //margin-left: 30px;
      height: 60px;
    }
    a{
      margin-top: 20px;
      color: white;
    }



    .gr1{
      display: flex;
      flex-direction: row;
      align-items: center;
      //text-transform: uppercase;
      font-weight: 500;
      font-size: xx-large;

    }
    .gr3{
      display: flex;
      flex-direction: row;
      align-items: center;
      //text-transform: uppercase;
      font-weight: 500;
      font-size: x-large;

    }
    .card1 {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 355px;
      margin-bottom: 20px;
    }

  }


  .cardgroup {
    //position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: auto;
    //@media (min-width: 1140px) {
    //  max-width: 1140px;
    //}



  }


</style>