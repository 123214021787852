<template>
  <div class="mainblock">
    <div class="bkimage" >

    </div>
    <div class="bkmap">
      <img class="pointer" src="@/assets/location-icon@2x.svg" alt="">
    </div>
    <div class="bkicons">
      <div class="card_i">
        <img src="@/assets/phone-icon@2x.svg" alt="">
        <h2>Phone</h2>
        <p>(408)734-5903</p>
      </div>
      <div class="card_i">
        <img src="@/assets/location-icon@2x.svg" alt="">
        <h2>Address</h2>

        <p href="https://maps.google.fr/maps?q=37.41097496980809, -121.98815603803438" target="_blank"> 1283 Mountain View, Alviso Road Sunnyvale, CA, 94089</p>
      </div>
      <div class="card_i">
        <img src="@/assets/mail-icon@2x.svg" alt="">
        <h2>Email</h2>
        <p>support@powerarchitects.com</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contacts",
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>

.mainblock {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #272547;
  //@media (min-width: 1140px) {
  //  max-width: 1140px;
  //}
  //max-width: 1140px
}

.bkimage {
//'url(' + video + ')'
  background-image: url( ~@/assets/02_15_22_0517_ws.jpg );
  background-size: cover;
  background-repeat: no-repeat;
  height: 540px;
  width: 1140px;
  max-width: 1140px;
  display: flex;
  flex-direction: inherit;
  //align-items: center;
  //justify-content: center
  margin-top: 40px;
  margin-bottom: 20px;
;

}
.bkmap {
  background-image: url(~@/assets/map.png);
  height: 540px;
  width: 1140px;
  max-width: 1140px;
  display: flex;
  flex-direction: inherit;
  align-items: center;
  justify-content: center

}
.pointer{
  margin-left: 25%;
}

.bkicons {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  justify-content: space-evenly;
  margin-top: 25px;
  //max-width: 1140px
  //@media (min-width: 1140px) {
  //  max-width: 1140px;
  //}
  h2 {
    font-size: large;
    font-weight: 800;
    color: white;

  }
  p {
    color: grey;
    font-size: 20px;
    letter-spacing: -0.02px;
    line-height: 34px;
    margin-top: 30px;

    //min-height: 306px;
    //width: 480px;
  }
  .card_i {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 120px;
    p {
      font-size: 20px;
    }
  }
}

</style>