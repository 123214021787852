import { createWebHistory, createRouter } from "vue-router";
import Home from "@/components/Home.vue";
import About from "@/components/about.vue";
import Products from "@/components/Products";
import Contacts from "@/components/Contacts";
import Xup from "@/components/xup";
import Valadd from "@/components/valadd";
import OEM from "@/components/oem";
import Init from '@/components/init'
//mobile
import Homemob from "@/components/Home800.vue";
import Aboutmob from "@/components/about800.vue";
import Contactsmob from "@/components/Contacts800.vue";
import XUPmob from "@/components/xup800";
import valaddmob from "@/components/valadd800";
import custommob from "@/components/oem800";

const routes = [
    {
        path: "/",
        name: "Root",
        component: Init,
    },
    {
        path: "/Home",
        name: "Home",
        component: Home,
    },
    {
        path: "/about",
        name: "About",
        component: About,
    },
    {
        path: "/products",
        name: "Products",
        component: Products,
    },
    {
        path: "/contacts",
        name: "Contacts",
        component: Contacts,
    },
    {
        path: "/xup",
        name: "xup",
        component: Xup,
    },
    {
        path: "/valadd",
        name: "valadd",
        component: Valadd,
    },
    {
        path: "/custom",
        name: "custom",
        component: OEM,
    },
    {
        path: "/homem",
        name: "Homemob",
        component: Homemob,
    },
    {
        path: "/aboutm",
        name: "Aboutmob",
        component: Aboutmob,
    },
    {
        path: "/contactsm",
        name: "Contactsmob",
        component: Contactsmob,
    },
    {
        path: "/xupm",
        name: "XUPmob",
        component: XUPmob,
    },
    {
        path: "/valaddm",
        name: "valaddmob",
        component: valaddmob,
    },
    {
        path: "/customm",
        name: "custommob",
        component: custommob,
    },
    {
        path: "/:catchAll(.*)", // Unrecognized path automatically matches to home/init
        redirect: '/',
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/',
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;